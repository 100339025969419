
export enum TipoFotoEnum {
  RoteiroCheckin = 'RoteiroCheckin',
  RoteiroCheckout = 'RoteiroCheckout',
  TarefaReabastecimentoAntes = 'TarefaReabastecimentoAntes',
  TarefaReabastecimentoDepois = 'TarefaReabastecimentoDepois',
  TarefaPesquisaValidade = 'TarefaPesquisaValidade',
  TarefaGaleriaFotos = 'TarefaGaleriaFotos',
  TarefaShareGondola = 'TarefaShareGondola',
  TarefaSugestaoPedido = 'TarefaSugestaoPedido',
  TarefaRegistroPresenca = 'TarefaRegistroPresenca',
  TarefaPontoPromocional = 'TarefaPontoPromocional',
  TarefaRegistroFotos = 'TarefaRegistroFotos',
  TarefaInformarCNPJ = 'TarefaInformarCNPJ',
  TarefaCMAntes = 'TarefaCMAntes',
  TarefaCMDepois = 'TarefaCMDepois',
  TarefaMPDV = 'TarefaMPDV',
  TarefaCaixasAbertas = 'TarefaCaixasAbertas',
  TarefaEstoque = 'TarefaEstoque',
  TarefaPrecoProduto = 'TarefaPrecoProduto',
  TarefaDescarte = 'TarefaDescarte',
  TarefaPesquisaConcorrente = 'TarefaPesquisaConcorrente',
  TarefaPontoExtra = 'TarefaPontoExtra',
  Ruptura = 'Ruptura',
  TarefaPesquisaForms = 'TarefaPesquisaForms',
  ArquivoExpress = 'ArquivoExpress',
}
